.complete-profile-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1e2124;
  z-index: 1000;
}

.complete-profile-content {
  width: 100%;
  max-width: 400px;
  padding: 2rem;
  background-color: #36393f;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2), 0 2px 4px -1px rgba(0, 0, 0, 0.1);
}

@media (max-width: 640px) {
  .complete-profile-content {
    max-width: 90%;
    padding: 1.5rem;
  }
}

.complete-profile-content h2 {
  margin-bottom: 1.5rem;
  color: #ffffff;
}

.complete-profile-content p {
  margin-bottom: 1.5rem;
  color: #b9bbbe;
}

.complete-profile-content form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.complete-profile-content label {
  display: block;
  margin-bottom: 0.5rem;
  color: #b9bbbe;
}

.complete-profile-content input,
.complete-profile-content select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #4f545c;
  border-radius: 0.25rem;
  background-color: #40444b;
  color: #ffffff;
}

.complete-profile-content input:focus,
.complete-profile-content select:focus {
  outline: none;
  border-color: #7289da;
  box-shadow: 0 0 0 3px rgba(114, 137, 218, 0.3);
}

.complete-profile-content button[type="submit"] {
  cursor: pointer;
  background-color: #7289da;
  color: #ffffff;
  border: none;
  padding: 0.75rem;
  border-radius: 0.25rem;
  font-weight: bold;
}

.complete-profile-content button[type="submit"]:hover {
  background-color: #677bc4;
}

.complete-profile-content button[type="submit"]:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}