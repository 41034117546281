.create-channel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.create-channel-modal {
  background-color: #1a1a1a; /* Darker background */
  border-radius: 8px;
  padding: 24px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.create-channel-modal h2 {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 24px;
  color: #ffffff;
}

.group-name-input,
.user-search-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 16px;
  border: 1px solid #444; /* Darker border */
  border-radius: 4px;
  font-size: 16px;
  background-color: #2d2d2d; /* Dark input background */
  color: #ffffff; /* White text */
}

.selected-users {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 16px;
}

.user-pill {
  display: flex;
  align-items: center;
  background-color: #2d2d2d; /* Darker background */
  border-radius: 16px;
  padding: 4px 8px;
  font-size: 14px;
  color: #ffffff; /* White text */
}

.user-pill span {
  margin-right: 4px;
}

.user-pill button {
  background: none;
  border: none;
  color: #999; /* Lighter color for better contrast */
  cursor: pointer;
  font-size: 16px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-list {
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 16px;
  border: 1px solid #444; /* Darker border */
  border-radius: 4px;
  background-color: #2d2d2d;
}

.user-item {
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid #444; /* Darker border */
  cursor: pointer;
  transition: background-color 0.2s;
  color: #ffffff; /* White text */
}

.user-item:last-child {
  border-bottom: none;
}

.user-item:hover {
  background-color: #3a3a3a; /* Slightly lighter on hover */
}

.user-item span {
  margin-left: 8px;
}

.user-item.selected {
  background-color: #1e3a5f; /* Darker blue for selected state */
}

.user-item .checkmark {
  margin-left: auto;
  color: #3b82f6; /* Blue checkmark */
}

.button-group {
  display: flex;
  justify-content: flex-end;
}

.cancel-button,
.create-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cancel-button {
  background-color: #4b5563; /* Gray button */
  color: #ffffff;
  margin-right: 8px;
}

.create-button {
  background-color: #3b82f6; /* Blue button */
  color: #ffffff;
}

.cancel-button:hover {
  background-color: #374151; /* Darker gray on hover */
}

.create-button:hover {
  background-color: #2563eb; /* Darker blue on hover */
}
