.auth-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1e2124;
  z-index: 1000;
  overflow: hidden;
}

.auth-content {
  width: 100%;
  max-width: 400px;
  margin: 20px;
  padding: 2rem;
  background-color: #36393f;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.2);
}

@media (min-width: 641px) and (max-width: 1024px) {
  .auth-content {
    max-width: 80%;
    margin: 40px;
  }
}

.auth-content h2 {
  margin-bottom: 1.5rem;
  color: #ffffff; /* White text */
}

.auth-content p {
  margin-bottom: 1.5rem;
  color: #b9bbbe; /* Light gray text */
}

.auth-content form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.auth-content label {
  display: block;
  margin-bottom: 0.5rem;
  color: #b9bbbe; /* Light gray text */
}

.auth-content input[type='email'] {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #4f545c; /* Dark gray border */
  border-radius: 0.25rem;
  background-color: #40444b; /* Slightly lighter input background */
  color: #ffffff; /* White text */
}

.auth-content input[type='email']:focus {
  outline: none;
  border-color: #7289da; /* Discord blue */
  box-shadow: 0 0 0 3px rgba(114, 137, 218, 0.3);
}

.auth-content button[type='submit'] {
  cursor: pointer;
  background-color: #7289da; /* Discord blue */
  color: #ffffff;
  border: none;
  padding: 0.75rem;
  border-radius: 0.25rem;
  font-weight: bold;
}

.auth-content button[type='submit']:hover {
  background-color: #677bc4; /* Slightly darker blue on hover */
}

.auth-content button[type='submit']:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
