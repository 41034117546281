.address-book-container {
  background-color: #1e1e1e;
  color: #ffffff;
  min-height: 100vh;
  height: 100%;
  margin-left: 0%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    padding-right: 2rem;
  }
}

body {
  margin: 0;
  padding: 0;
  background-color: #1e1e1e;
}

.address-book-main {
  flex: 1;
  padding: 1rem;
  overflow-y: auto;

  @media only screen and (max-width: 768px) {
    padding-right: 0;
  }
}

.address-book-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0rem;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.refresh-button {
  background-color: #4a4a4a;
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;
}

.refresh-button:hover {
  background-color: #5a5a5a;
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.search-and-sort {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;

  @media only screen and (max-width: 992px) {
    margin-bottom: 2rem;
  }
}

.contacts-sections {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;

  @media only screen and (max-width: 992px) {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

.section {
  background-color: #2d2d2d;
  border-radius: 0.75rem;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  flex-grow: 1;
  flex-shrink: 1;

  @media only screen and (max-width: 992px) {
    padding-right: 0;
  }
}

.section:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.section-header {
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
}

.section-header svg {
  margin-right: 0.5rem;
}

.no-contacts {
  color: #888888;
  text-align: center;
  font-style: italic;
}

.contact-list {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 0.5rem;
}

.contact-list::-webkit-scrollbar {
  width: 6px;
}

.contact-list::-webkit-scrollbar-thumb {
  background-color: #4a4a4a;
  border-radius: 3px;
}

.contact-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ccc;

  @media only screen and (max-width: 768px) {
    flex-direction: column !important;
    align-items: flex-start;
    gap: 1rem;
  }
}

.contact-info {
  flex-grow: 1;
  margin-left: 10px;
}

.contact-actions {
  display: flex;
  gap: 5px;
}

.contact-actions button {
  white-space: nowrap;
}

.contact-avatar {
  width: 40px;
  height: 40px;
  background-color: #4a90e2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  margin-right: 1rem;

  @media only screen and (max-width: 768px) {
    margin-right: 0;
  }
}

.contact-name {
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.contact-email {
  font-size: 0.9rem;
  color: #aaaaaa;
}

.contact-status {
  font-size: 0.8rem;
  color: #4a90e2;
  margin-top: 0.25rem;
}

.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.last-invite-url {
  margin-top: 20px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.invite-url-container {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.invite-url-input {
  flex-grow: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-right: 10px;
}

.copy-button {
  display: flex;
  align-items: center;
  gap: 5px;
}
