:root {
  --app-height: 100%;
  --min-workspace-width: 330px;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  min-height: 100%;
  height: auto;
}

.messaging__sidebar {
  flex: 0 0 auto;
}

.str-chat__channel {
  flex: 1;
  min-width: 0;
}

.str-chat__main-panel {
  flex: 1;
  min-width: 0;
}

.str-chat__main-panel--thread-open {
  display: none;
}

.str-chat__thread {
  flex: 0 1 var(--min-workspace-width);
  max-width: 45%;
}

/* Mobile View */
@media screen and (max-width: 640px) {
  /*
     * This is to prevent the browser navigation bars from hiding the app
     * on some mobile browsers. The app-height variable is set dynamically
     * using javascript.
     */
  .str-chat__channel {
    height: var(--app-height);
  }

  #mobile-channel-list {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    transform: translate3d(-100vw, 0, 0);
    transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  }

  #mobile-channel-list.show {
    transform: translate3d(0vw, 0, 0);
    z-index: 1000;
  }
}

/* To fix inherited styles (as per Team and Customer Support apps */
@media screen and (max-width: 960px) {
  .str-chat__channel-list {
    position: unset;
    left: unset;
    top: unset;
    z-index: unset;
    min-height: unset;
    overflow-y: unset;
    transition: unset;
  }

  .str-chat__thread {
    flex: 1;
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    max-width: 100%;
  }
}

.str-chat.messaging__sidebar,
.str-chat.messaging.str-chat__channel {
  height: 100vh;
}

.messaging__channel-list__header__name {
  width: 155px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical;
  display: block !important;
}

.channel-preview__content-top {
  overflow: visible !important;
  display: flex !important;
  justify-content: space-between;
}

.channel-preview__content-message > p {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical;
  display: block !important;
}

@media screen and (min-width: 641px) and (max-width: 1024px) {
  .app-container {
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    overflow: hidden;
  }

  .app-chat-container {
    width: 100%;
    height: 100%;
  }

  .str-chat__channel {
    height: calc(100vh - 60px);
    height: calc(100dvh - 60px);
  }
}
