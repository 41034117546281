@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --app-height: 100%;
}

html,
body {
  @apply overscroll-none;
  overflow-y: auto !important;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  min-height: 100%;
  min-width: 100%;
  color: white;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: env(safe-area-inset-bottom);
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  position: fixed;
  overflow: hidden;
}

body[data-scroll-locked] {
  min-width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  display: flex;
}

.mainContent {
  flex: 1;
  margin-left: 360px; /* 60px for sidebar + 300px for channel list */
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 768px) {
  .mainContent {
    margin-left: 0;
    margin-bottom: 60px;
  }
}

.bg-app {
  background-color: #212326;
}

#mobile-channel-list .str-chat {
  padding-inline: 6px;
}

#root > .str-chat {
  width: 290px !important;
}

.messaging__channel-header {
  z-index: 50;
  position: relative;
  top: 0;
  width: calc(100% - 342px);

  @media (max-width: 640px) {
    top: 50px;
    width: 100%;
  }
}

.channel-preview__content-top,
.channel-preview__content-message {
  max-width: 205px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical;
}

.str-chat__channel-list-messenger__main {
  overflow: hidden !important;
}

.channel-preview__container .avatar-group {
  margin: 4px !important;
}

.dot-spinner {
  --uib-size: 4.8rem;
  --uib-speed: 0.9s;
  --uib-color: #5b9bf2;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--uib-size);
  width: var(--uib-size);
}

.dot-spinner__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.dot-spinner__dot::before {
  content: '';
  height: 20%;
  width: 20%;
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  opacity: 0.5;
  animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
  box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}

.dot-spinner__dot:nth-child(2) {
  transform: rotate(45deg);
}

.dot-spinner__dot:nth-child(2)::before {
  animation-delay: calc(var(--uib-speed) * -0.875);
}

.dot-spinner__dot:nth-child(3) {
  transform: rotate(90deg);
}

.dot-spinner__dot:nth-child(3)::before {
  animation-delay: calc(var(--uib-speed) * -0.75);
}

.dot-spinner__dot:nth-child(4) {
  transform: rotate(135deg);
}

.dot-spinner__dot:nth-child(4)::before {
  animation-delay: calc(var(--uib-speed) * -0.625);
}

.dot-spinner__dot:nth-child(5) {
  transform: rotate(180deg);
}

.dot-spinner__dot:nth-child(5)::before {
  animation-delay: calc(var(--uib-speed) * -0.5);
}

.dot-spinner__dot:nth-child(6) {
  transform: rotate(225deg);
}

.dot-spinner__dot:nth-child(6)::before {
  animation-delay: calc(var(--uib-speed) * -0.375);
}

.dot-spinner__dot:nth-child(7) {
  transform: rotate(270deg);
}

.dot-spinner__dot:nth-child(7)::before {
  animation-delay: calc(var(--uib-speed) * -0.25);
}

.dot-spinner__dot:nth-child(8) {
  transform: rotate(315deg);
}

.dot-spinner__dot:nth-child(8)::before {
  animation-delay: calc(var(--uib-speed) * -0.125);
}

@keyframes pulse0112 {
  0%,
  100% {
    transform: scale(0);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}

#mobile-nav-icon {
  position: relative;
}

#mobile-nav-icon svg {
  margin-bottom: 15px !important;
  margin-top: 10px !important;
  margin-inline: 0 !important;
}

.pulse {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 34px;
  width: 34px;
  height: 34px;
  display: block;
  animation: 1.5s pulse infinite ease-in-out;
  opacity: 0.4;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #69ffa8;
  }

  100% {
    box-shadow: 0 0 0 14px #69ffa800;
  }
}

#mobile-nav-icon {
  display: none !important;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  opacity: 100;
  transition: opacity 0.3s ease-in-out;

  &.hidden {
    opacity: 0;
  }
}

.str-chat__message-bubble {
  position: relative;
}

.str-chat__message-simple-text-inner {
  overflow: hidden !important;
}

.str-chat__message.str-chat__message--me .str-chat__message-bubble {
  background-color: #3497da !important;
}

svg#spinner {
  width: 14px;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
  margin-left: 10px;
}

#spinner circle {
  fill: none;
  stroke: #3497da;
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
}

@keyframes rotate4 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash4 {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}

.messaging__sidebar {
}

#settings-img-upload .mx-auto {
  margin-left: unset !important;
  margin-right: unset !important;
}

.text-entry-box {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: env(safe-area-inset-bottom);
  z-index: 100;
}

#horizontal-sidebar {
  display: none;
  padding-top: 8px;
}

#vertical-sidebar {
  display: flex;
}

.app-chat-container {
  display: flex;
  width: 100vw;
  /* padding-left: 50px; */
}

.messaging-channel-header-wrapper {
  position: sticky !important;
  /* top: 50px; */
  z-index: 100;
}

.horizontal-sidebar {
  display: none;
  position: fixed !important;
  top: 0;
  width: 100%;
  height: 50px;
  z-index: 1000;
}

@media (max-width: 640px) {
  .horizontal-sidebar {
    display: flex;
  }

  .app-container {
    padding-top: 50px;
  }

  .app-chat-container {
    padding-left: 0px;
  }

  .str-chat__list {
    padding-bottom: 128px;
  }

  #horizontal-sidebar {
    display: flex;
  }

  #vertical-sidebar {
    display: none;
  }
}

/* Custom styles for select components */
.select-content {
  @apply bg-gray-700 border border-gray-600 rounded-md shadow-lg;
}

.select-item {
  @apply px-4 py-2 text-sm text-white hover:bg-blue-600 cursor-pointer transition-colors duration-150 ease-in-out;
}

.select-item[data-highlighted] {
  @apply bg-blue-600;
}

.select-trigger {
  @apply bg-gray-700 border border-gray-600 text-white hover:bg-gray-600 transition-colors duration-150 ease-in-out;
}

/* Scrollbar styles for Webkit browsers */
.select-content::-webkit-scrollbar {
  width: 8px;
}

.select-content::-webkit-scrollbar-track {
  @apply bg-gray-700;
}

.select-content::-webkit-scrollbar-thumb {
  @apply bg-gray-500 rounded-full;
}

.select-content::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-400;
}

.str-chat__input-voice-recorder {
  padding-inline: 6px;
  padding-top: 4px;
}

.str-chat__message-attachment__voice-recording-widget {
  grid-template-columns: auto auto !important;
}

.str-chat__message-attachment__voice-recording-widget__right-section {
  display: none;
}

.str-chat__message-attachment__voice-recording-widget__title {
  display: none;
}

.str-chat__attachment-list {
  display: flex !important;
  flex-direction: row !important;
  width: 100% !important;
}

.str-chat__message-attachment--voiceRecording {
  width: 100% !important;
}

.str-chat__typing-indicator--typing {
  bottom: -10px !important;
}

#bottom-typing-indicator {
  position: relative;
  top: 0;

  @media (max-width: 640px) {
    top: -62px !important;
  }
}

#root {
  height: 100%;
  width: 100%;
}
