@import 'App.layout.css';
@import 'App.theme.css';

@import "overrides/index.css";

@keyframes flyOff {
  0%, 50% {
    transform: translate(-50%, 0) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -100px) rotate(20deg);
    opacity: 0;
  }
}

@keyframes fadeOut {
  0%, 50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.animate-fly-off {
  animation: flyOff 3s ease-out forwards;
}

.animate-fade-out {
  animation: fadeOut 3s ease-out forwards;
}