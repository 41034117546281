.croppie-container {
  width: 300px;
  height: 300px;
  background-color: #2D2D2D;
  padding: 20px;
  border-radius: 8px;
}

.croppie-container .cr-boundary {
  border-radius: 50%;
}

.croppie-container .cr-slider-wrap {
  margin-top: 15px;
}

.croppie-container .cr-slider {
  width: 100%;
}

.croppie-container .cr-slider::-webkit-slider-thumb {
  background-color: white;
}

.croppie-container .cr-slider::-moz-range-thumb {
  background-color: white;
}

.croppie-container .cr-slider::-ms-thumb {
  background-color: white;
}